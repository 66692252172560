<template>

    <div class="form-compact form-compact-company">

      <div class="form-compact-header">
        <v-system-bar dark color="primary">
          <v-icon class="mr-1">{{ $t('COMPANY.ICON') }}</v-icon>
          <template v-if="id">
            <small><strong class="text-uppercase">{{ $t('COMPANY.FORM_TITLE_UPDATE') }}</strong></small>
            <v-icon class="ml-4" size="12">mdi-pound</v-icon>
            <small>{{ loaded_values.id }}</small>
          </template>
          <small v-else><strong class="text-uppercase">{{ $t('COMPANY.FORM_TITLE_CREATE') }}</strong></small>
          <v-spacer/>
          <v-icon
            small
            class="clickable"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-system-bar>

        <v-toolbar
          color="transparent"
          flat
          height="66px"
        >
          <v-toolbar-action v-if="loaded_values && loaded_values.images" class="mr-4">
            <v-img :src="$getImgSrc(loaded_values.images, 'md', '/img/empty-image.png')" max-height="35px" max-width="80px" cover />
          </v-toolbar-action>
          <v-toolbar-title class="primary--text" >
            <span v-if="id" class="text-capitalize">{{ loaded_values.title}}</span>
            <span v-else>{{ $t('COMPANY.FORM_TITLE_NEW') }}</span>
          </v-toolbar-title>
          <v-spacer />
          <div class="mr-6" >
            <status-label :item="loaded_values"/>
          </div>
          <div class="mr-6 text-nowrap" v-if="ids.length > 1">
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  depressed
                  color="grey"
                  :disabled="!canPrev"
                  @click="prevItem"
                  class="mr-2"
                >
                  <v-icon color="white">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.PREVIOUS') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  depressed
                  color="grey"
                  :disabled="!canNext"
                  @click="nextItem"
                >
                  <v-icon color="white">mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.NEXT') }}
            </v-tooltip>
          </div>
          <div class="mr-6 d-flex text-nowrap" v-if="id">
            <div>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="error"
                    class="mr-2"
                    @click="removeItem"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.REMOVE') }}
              </v-tooltip>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="warning"
                    class="mr-2"
                    @click="resetItem"
                  >
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.RESET') }}
              </v-tooltip>
            </div>
          </div>
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                depressed
                color="success"
                @click="saveItem"
                :disabled="!valid"
              >

                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.SAVE') }}
          </v-tooltip>
        </v-toolbar>
        <div class="toolbar-separator primary ml-4 mr-4"/>

        <v-dialog v-model="showSaveMultiple" width="350px" overlay-color="transparent">
          <v-card class="pa-4">
            <p class="ma-6 primary--text">
              {{$tc('COMMON.CONFIRM_MULTIPLE_ITEMS', this.ids.length)}}
            </p>
            <div class="d-flex">
              <v-btn rounded depressed large @click="showSaveMultiple = false">
                <v-icon class="mr-2">mdi-close</v-icon>{{$t('COMMON.CANCEL')}}
              </v-btn>
              <v-spacer />
              <v-btn color="success" rounded depressed large @click="saveItem(true)">
                <v-icon class="mr-2">mdi-check</v-icon>{{$t('COMMON.CONFIRM')}}
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

      </div>

      <div :class="['form-compact-content', blocked ? 'blocked' : '']">
        <v-overlay
          :value="blocked"
          v-if="loaded_values"
          color="white"
          :opacity="0.75"
          absolute
        >
          <div class="text-center">
            <!-- <v-icon :color="loaded_values.status_type" size="42">{{ $t(`${loaded_values.status_code}_ICON`) }}</v-icon>
            <p class="black--text">{{ $t(loaded_values.status_code) }}</p> -->
            <div class="mt-4">
              <v-btn
                depressed
                small
                color="grey"
                @click="loadItem"
              >
                {{ $t('COMMON.RELOAD') }}
              </v-btn>
            </div>
          </div>
        </v-overlay>
        <Loading :value="!!loading" />
        <v-container fluid class="pt-4 pb-10">
          <v-form v-model="valid">

            <div class="pr-6 pl-6">
              <FormCompanyCompactIntro @input="handleInput" :values="values" :loaded_values="loaded_values" />
            </div>

          </v-form>
        </v-container>
      </div>

    </div>

</template>

<script>

import Loading from '@/components/Loading'
import StatusLabel from '@/components/StatusLabel'

import FormCompanyMixins from '../FormCompanyMixins'
import FormCompanyCompactIntro from './FormCompanyCompact.intro'


export default {
  name:"FormCompanyCompact",
  mixins: [FormCompanyMixins],
  components: {
    Loading,
    StatusLabel,
    FormCompanyCompactIntro,
  },
  data: () => ({
    valid: false,
    showReport: false,
  }),
  computed: {
    blocked () {
      return this.loaded_values ? ['info', 'warning'].indexOf(this.loaded_values.status_type) !== -1 : false
    }
  },
  watch: {
    blocked (n) {
      if (!n) this.stopInterval()
      else this.startInterval()
    }
  },
  beforeDestroy () {
    this.stopInterval()
  },
  methods: {
    startInterval () {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        this.loadItem()
      }, 5000)
    },
    stopInterval () {
      clearInterval(this.interval)
      this.$emit('update')
    }
  }
}
</script>
